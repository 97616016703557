import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
import SVG from 'react-inlinesvg'
import { Swiper, SwiperSlide } from 'swiper/react'
import useWindowSize from '@base/utils/hooks/useWindowSize'
import Markdown from '@base/parts/markdown'
import ScrollDisplay from '@base/utils/helpers/scroll-display'
import Image from '@base/parts/image'
import stars from '@icons/stars.svg'
import { Thumbs, Controller, Parallax, Lazy, EffectFade, EffectCreative } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-creative'

const Characters = ({ data, pageContext }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  const [charactersView, setCharactersView] = useState(null)
  const windowSize = useWindowSize()

  useEffect(() => {
    if (windowSize.width <= 991) {
      setCharactersView('mobile')
    } else {
      setCharactersView('desktop')
    }
  }, [])

  return (
    <section
      className="relative h-auto flex flex-row justify-center items-center z-10"
      data-section="characters"
    >
      <div id="characters" className="absolute -top-[45px] lg:-top-44"></div>
      <div className=" clip-wave-container w-full h-full">
        <div className="characters-block clip-wave w-full min-h-screen-1/2 bg-blue">
          <StaticImage
            src='../../assets/img/characters-background.jpg'
            alt=""
            placeholder="blurred"
            className="gatsby-absolute w-full h-full object-cover object-center lg:object-top z-under"
          />
          <div className="flex flex-col lg:flex-row h-full py-20 lg:px-16">

            <div className="px-8 lg:hidden">
              <h2 className={`font-displayCondensed mb-10 leading-none w-full text-center ${pageContext.locale === 'fr' ? 'smaller-text' : ''}`}>
                <svg className="w-full h-40">
                  <text x="50%" y="40%" textAnchor="middle" className="style-secondary style-inverted">
                    <Markdown
                      children={data.title}
                      type="svg"
                    />
                  </text>
                  <text x="50%" y="90%" textAnchor="middle" className="style-secondary style-inverted">
                    <Markdown
                      children={data.title2}
                      type="svg"
                    />
                  </text>
                </svg>
              </h2>
              {charactersView === 'mobile' && (
                <Swiper
                  slidesPerView="1"
                  modules={[Thumbs, Controller, Parallax, EffectFade, EffectCreative, Lazy]}
                  loop
                  effect={"fade"}
                  thumbs={{ swiper: thumbsSwiper }}
                  allowTouchMove={false}
                  onSwiper={setSecondSwiper}
                  controller={{ control: firstSwiper }}
                  className="characters-slider-text h-full"
                >
                  {data.characters.map((slide, i) => (
                    <SwiperSlide
                      className="characters-slide-text"
                      key={`slider-description-${i}`}
                    >
                      <p className="w-full xl:w-3/4 mb-10 lg:mb-12 xxl:mb-24 text-base text-brown">
                        <Markdown children={slide.description} />
                      </p>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>

            <ScrollDisplay className="w-full lg:w-1/2 h-full xxl:h-auto relative lg:pr-16 lg:mr-16">
              <StaticImage
                src='../../assets/img/characters-frame.png'
                alt=""
                placeholder="blurred"
                className="characters-frame h-full lg:h-auto w-full px-10 lg:px-0 mb-12 lg:mb-0"
              />
              <div className="absolute w-full h-full top-0 left-0 z-10">
                <Swiper
                  slidesPerView="1"
                  modules={[Thumbs, Controller, Parallax, EffectFade, EffectCreative, Lazy]}
                  loop
                  effect={"creative"}
                  creativeEffect={{
                    prev: {
                      translate: [0, 0, 0],
                      opacity: 0
                    },
                    next: {
                      translate: ["5%", 0, 0],
                      opacity: 1
                    },
                  }}
                  speed={750}
                  thumbs={{ swiper: thumbsSwiper }}
                  allowTouchMove={false}
                  onSwiper={setFirstSwiper}
                  controller={{ control: secondSwiper }}
                  parallax={true}
                  preloadImages={false}
                  lazy={{ loadPrevNext: true }}
                  className="characters-slider h-full"
                >
                  {data.characters.map((slide, i) => (
                    <SwiperSlide
                      className="characters-slide"
                      key={`slider-slide-${i}`}
                    >
                      <motion.div
                        initial={{ opacity: 0, top: 20 }}
                        animate={{ opacity: 1, top: 0 }}
                        exit={{ opacity: 0, top: 20 }}
                        transition={{
                          duration: 0.75,
                        }}
                        data-swiper-parallax="-200"
                        className="relative top-0 w-full h-full flex justify-center"
                      >
                        <SVG
                          onError={error => console.log(error.message)}
                          cacheRequests={true}
                          preProcessor={code =>
                            code.replace(/fill=".*?"/g, 'fill="currentColor"')
                          }
                          src={stars}
                          className="absolute z-10 -top-2 sm:top-0 xxl:top-10 left-28 sm:left-1/4 lg:left-32 xl:left-64 xxl:left-80 xxxl:left-96 text-white h-auto w-auto scale-75 sm:scale-100"
                        />
                        <Image
                          media={slide.image.full}
                          className="gatsby-absolute bottom-24 sm:bottom-32 xl:bottom-20 xxl:bottom-28"
                        />
                      </motion.div>
                      <div className="absolute bottom-12 sm:bottom-24 lg:bottom-8 w-full mx-auto">
                        <div data-swiper-parallax="100">
                          <h4 className="mb-10 h-20 lg:h-40 leading-none w-full">
                            <svg className="w-full h-full">
                              <text x="50%" y="70%" textAnchor="middle" className="style-fourth style-inverted">
                                <Markdown
                                  children={slide.title}
                                  type="svg"
                                />
                              </text>
                            </svg>
                          </h4>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </ScrollDisplay>
            <div className="w-full lg:w-1/2 flex items-center">
              <ScrollDisplay className="px-0 w-full" delay={0.5}>
                <div className="hidden lg:block">
                  <h2 className="font-displayCondensed mb-10 leading-none w-full">
                    <svg className="w-full h-48 xxl:h-68">
                      <text x="1%" y="37%" className="style-secondary style-inverted">
                        <Markdown
                          children={data.title}
                          type="svg"
                        />
                      </text>
                      <text x="1%" y="87%" className="style-secondary style-inverted">
                        <Markdown
                          children={data.title2}
                          type="svg"
                        />
                      </text>
                    </svg>
                  </h2>
                  {charactersView === 'desktop' && (
                    <Swiper
                      slidesPerView="1"
                      modules={[Thumbs, Controller, Parallax, EffectFade, EffectCreative, Lazy]}
                      loop
                      effect={"fade"}
                      thumbs={{ swiper: thumbsSwiper }}
                      allowTouchMove={false}
                      onSwiper={setSecondSwiper}
                      controller={{ control: firstSwiper }}
                      className="characters-slider-text h-full"
                    >
                      {data.characters.map((slide, i) => (
                        <SwiperSlide
                          className="characters-slide-text"
                          key={`slider-description-${i}`}
                        >
                          <p className="w-full xl:w-3/4 mb-10 lg:mb-12 xxl:mb-24 text-base text-brown">
                            <Markdown children={slide.description} />
                          </p>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </div>

                <Swiper
                  slidesPerView="auto"
                  allowTouchMove={false}
                  modules={[Thumbs]}
                  watchSlidesProgress
                  onSwiper={setThumbsSwiper}
                  className="characters-thumbs"
                >
                  {data.characters.map((thumb, i) => (
                    <SwiperSlide
                      className="characters-thumb pointer-events-none mr-10 lg:mr-16 mb-32 lg:mb-10 cursor-pointer "
                      key={`slider-thumb-${i}`}
                    >
                      <div className={`pointer-events-auto relative flex items-center justify-center h-auto w-3/4 sm:h-52 sm:w-52 xl:h-72 xl:w-72 text-white `}>
                        <div className="characters-mask absolute h-full w-full z-10">
                          <Image
                            media={thumb.image.thumbnail}
                            className="h-auto w-auto gatsby-absolute bottom-0"
                          />
                        </div>
                        <StaticImage
                          src='../../assets/img/characters-frame-small.png'
                          alt=""
                          placeholder="blurred"
                          className="character-frame h-full w-full"
                        />
                        {thumb.title &&
                          <span className="characters-title h3 absolute -bottom-8 z-10 w-full">
                            <svg className="w-full h-24">
                              <text x="50%" y="35%" textAnchor="middle" className="style-fourth style-inverted">
                                <Markdown
                                  children={thumb.text}
                                  type="svg"
                                />
                              </text>
                              <text x="50%" y="85%" textAnchor="middle" className="style-fourth style-inverted">
                                <Markdown
                                  children={thumb.title2}
                                  type="svg"
                                />
                              </text>
                            </svg>
                            <SVG
                              onError={error => console.log(error.message)}
                              cacheRequests={true}
                              preProcessor={code =>
                                code.replace(/fill=".*?"/g, 'fill="currentColor"')
                              }
                              src={stars}
                              className="absolute z-10 -top-2 -right-8 sm:right-0 w-14 h-auto pointer-events-none"
                            />
                          </span>
                        }
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </ScrollDisplay>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Characters
