import React, { useContext, useEffect, useRef } from 'react'
import { Button } from '@bhvr/web-design-system'

import ScrollDisplay from '@base/utils/helpers/scroll-display'
import Markdown from '@base/parts/markdown'
import { validateSubscription, validationMessage } from '@base/utils/helpers/subscription/form-validation'
import { mailchimpHandler } from '@base/utils/helpers/subscription/handler-mailchimp'

import { Context } from '@/contexts/newsletter-context'
import { iterableHandler } from '../../utils/helpers/subscription/handler-iterable'

const Newsletter = ({ pageContext, data, strings, className, list }) => {
  const { ageGatePrompt, legalAge, formSubmit, formFields } = useContext(Context)
  const [ageGateDisplayed, setAgeGateDisplayed] = ageGatePrompt
  const [formSubmitted, setFormSubmitted] = formSubmit
  const [isLegalAge, setLegalAge] = legalAge
  const { newsletterData, ageGateData } = data
  const errorMessages = strings.error

  const form = useRef(null)
  const someField = useRef(null)
  const emailField = useRef(null)
  const consentField = useRef(null)
  const newsletterErrorField = useRef(null)
  const newsletterErrorFieldMessage = useRef(null)
  let fieldsMap = []

  let dataLayer = {
    pageContext: pageContext,
    eventCategory: 'form',
    eventAction: 'newsletter subscription',
    eventType: 'form',
    eventLocation: 'footer'
  }

  let newsletterErrorMessage = ''

  useEffect(() => {
    fieldsMap = {
      form: form.current,
      someField: someField.current,
      emailField: emailField.current,
      consentField: consentField.current,
      newsletterErrorField: newsletterErrorField.current,
      newsletterErrorFieldMessage: newsletterErrorFieldMessage.current
    }
    //setFormSubmitted(false)
  })

  async function submitToHandler(formData) {
    if ((process.env.GATSBY_MAILCHIMP_ACCOUNT && process.env.GATSBY_MAILCHIMP_LIST_ID) || process.env.GATSBY_ITERABLE_KEY || process.env.GATSBY_CRM_HANDLER) {
      new Promise((resolve) => {
        let data = ''
        // Select wich handler to use depending of the available variables (if both available, Mailchimp will be use by default)
        if (process.env.GATSBY_MAILCHIMP_ACCOUNT && process.env.GATSBY_MAILCHIMP_LIST_ID) {
          data = mailchimpHandler(formData)
        } else if (process.env.GATSBY_CRM_HANDLER === 'iterable') {
          const currentLocale = pageContext.locale
          data = iterableHandler(formData, currentLocale, list)
        }
        resolve(data)
      }).then(function (data) {
        const message = data.msg ? data.msg : data.result
        validationMessage(fieldsMap, errorMessages, false, data.result, message)
      })
    } else {
      alert('No subscription handler available. Please define one.')
      return false
    }
  }

  const submitForm = e => {
    e.preventDefault()

    // Validate Form here
    const validForm = validateSubscription(fieldsMap, errorMessages)

    {/* Submit Form */ }
    if (validForm) {
      const formData = Object.fromEntries(new FormData(form.current).entries())
      submitToHandler(formData)
    }
  }

  /*   useEffect(() => {
      if (formSubmitted === true && isLegalAge) {
        const formData = Object.fromEntries(new FormData(form.current).entries())
        submitToHandler(formData)
      }
    }, [formSubmitted]) */

  // Scroll to form based on URL parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const newsletterParam = params?.has('newsletter') ? true : false
    const newsletterAnchor = document.getElementById('newsletterSectionAnchor')

    if (typeof window !== 'undefined' && newsletterParam && newsletterAnchor) {
      window.document.body.insertAdjacentHTML('afterbegin', '<div class="anchor-page-loading opacity-0"></div>')
      document.querySelector('.anchor-page-loading').classList.add('opacity-100')
      newsletterAnchor.scrollIntoView({ block: 'start' })
      setTimeout(function () {
        newsletterAnchor.scrollIntoView({ block: 'start' })
        document.querySelector('.anchor-page-loading').classList.remove('opacity-100')
        setTimeout(function () {
          newsletterAnchor.scrollIntoView({ block: 'start' })
          document.querySelector('.anchor-page-loading').remove()
        }, 500)
      }, 1000)
    }
  }, [])

  return (
    <>
      <span id="newsletterSectionAnchor" className="newsletter-section-anchor absolute -top-16 lg:-top-32" />
      <section className="relative overflow-hidden text-white">
        <ScrollDisplay className="order-t flex w-full flex-col px-10 lg:px-0 lg:justify-between">
          <h2 className={`mb-6 leading-none text-center w-full style-secondary ${pageContext.locale === 'fr' ? 'small-text' : ''}`}>
            <svg className="w-full h-32 lg:h-28">
              <g className="gatsby-hidden-mobile">
                <text x="50%" y="70%" textAnchor="middle" className="style-secondary">
                  <Markdown
                    children={newsletterData.title}
                    type="svg"
                  />
                  &nbsp;
                  <Markdown
                    children={newsletterData.title2}
                    type="svg"
                  />
                </text>
              </g>
              <g className="gatsby-hidden-desktop">
                <text x="50%" y="40%" textAnchor="middle" className="style-secondary">
                  <Markdown
                    children={newsletterData.title}
                    type="svg"
                  />
                </text>
                <text x="50%" y="90%" textAnchor="middle" className="style-secondary">
                  <Markdown
                    children={newsletterData.title2}
                    type="svg"
                  />
                </text>
              </g>
            </svg>
          </h2>
          <div className="m-auto w-full md:w-3/4 xl:w-1/2">
            <div className="w-full xl:w-3/4 mx-auto">
              {newsletterData.description && (
                <p className="mb-10 text-sm lg:text-md text-green text-center font-bold">
                  {newsletterData.description}
                </p>
              )}
            </div>
            <form
              className="relative"
              ref={form}
              onSubmit={submitForm}
              noValidate
            >
              <div className="mb-6 flex flex-col lg:flex-row sm:items-end">
                {/* Form Base fields */}
                <fieldset className="flex w-full lg:w-1/2 flex-col mb-6 lg:mb-0">
                  <label
                    className="font-bold mb-4 pl-2 text-base leading-none tracking-wide text-blueLight"
                    htmlFor="mce-EMAIL"
                  >
                    {newsletterData.emailLabel}
                  </label>
                  <input
                    ref={emailField}
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    placeholder={newsletterData.emailPlaceholder}
                    className="h-20 border-2 border-blueLight bg-transparent px-12 py-4 text-white text-base rounded-none w-full lg:w-auto"
                  />
                </fieldset>
                <Button
                  secondary
                  type="submit"
                  className="btn button button-submit button-narrow h-20 w-full lg:w-1/2"
                >
                  <span>{newsletterData.emailButton}</span>
                </Button>
              </div>

              {/* Error Dialog */}
              <p
                ref={newsletterErrorField}
                className="newsletter-message relative z-10 flex items-center pointer-events-none"
              >
                <svg className="error-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z" /></svg>
                <svg className="success-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-5.541 8.409l-1.422-1.409-7.021 7.183-3.08-2.937-1.395 1.435 4.5 4.319 8.418-8.591z" /></svg>
                <span ref={newsletterErrorFieldMessage}>
                  {newsletterErrorMessage}
                </span>
              </p>

              {/* Honeypot */}
              <div
                className="some-field"
                aria-hidden="true"
              >
                <input
                  ref={someField}
                  type="text"
                  name="b_5547c2b6258a4a891a7bf1bb4_08654e19bf"
                  tabIndex="-1"
                />
              </div>

              {/* Consent and Legal Fields */}
              <div className="mt-6 mb-12 flex border border-white px-12 py-6">
                <label
                  htmlFor="mce-consent"
                  className="relative pl-10 text-xs tracking-wide"
                >
                  <input
                    ref={consentField}
                    className="mr-3"
                    type="checkbox"
                    name="consent"
                    id="mce-consent"
                  />
                  <Markdown children={newsletterData.agreementText} />
                </label>
              </div>
              <div className="bg-grayMedium/20 px-12 py-8 text-sm tracking-wide">
                <Markdown children={newsletterData.legalText} />
              </div>
            </form>
          </div>
        </ScrollDisplay>
      </section>
    </>
  )
}

export default Newsletter
