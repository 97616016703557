import React, { createContext } from 'react'
import Hero from '@/components/blocks/hero'
import Intro from '@/components/blocks/intro'
import Characters from '@/components/blocks/characters'
import TextImage from '@/components/blocks/text-image'
import Subscribe from '@/components/blocks/subscribe'
import Gallery from '@/components/blocks/gallery'

export const Context = createContext()

// Map blocks to section components
const blockComponents = {
  'sections.hero': Hero,
  'sections.intro': Intro,
  'sections.characters': Characters,
  'sections.text-image': TextImage,
  'sections.subscribe': Subscribe,
  'sections.gallery': Gallery
}

// Display a section individually
const Block = ({ pageContext, sectionData, locale }) => {
  let componentName
  let BlockComponent
  componentName = sectionData.strapi_component ? sectionData.strapi_component : sectionData.data_component
  BlockComponent = blockComponents[componentName]

  if (!BlockComponent) {
    return null
  }

  // Display the section
  return (
    <BlockComponent
      pageContext={{ ...pageContext }}
      data={sectionData}
      locale={locale}
    />
  )
}

// Display the list of blocks
const Blocks = ({ pageContext, blocks, locale }) => {
  if (blocks !== null) {
    return (
      <>
        {blocks.map((section) => (
          <Block
            pageContext={{ ...pageContext }}
            sectionData={section}
            locale={locale}
            key={section.id}
          />
        ))}
      </>
    )
  }
  return ''
}

export default Blocks
