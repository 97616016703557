import React from 'react'
import useWindowSize from '@base/utils/hooks/useWindowSize'
import Markdown from '@base/parts/markdown'
import { StaticImage } from 'gatsby-plugin-image'
import ScrollDisplay from '@base/utils/helpers/scroll-display'
import Button from '@base/parts/button'
import StyledButton from '@elements/styled-button'
import Image from '@base/parts/image'
import stars from '@icons/stars.svg'
import SVG from 'react-inlinesvg'

const TextImage = ({ data, pageData }) => {
  const windowSize = useWindowSize()
  let lineBreak = ''
  let horizontalAlign = ''
  if (windowSize.width <= 991) {
    lineBreak = '1.4em'
    horizontalAlign = '50%'
  }

  return (
    <>
      <section
        className={`block-text-image relative h-auto flex flex-col lg:flex-row -mb-[2px] ${data.layout === 'left' ? 'bg-blueLight' : 'bg-beige'}`}
        data-section="deadbydaylight"
      >
        <div id={data.anchorId} className="absolute -top-16 lg:-top-44"></div>
        <div className={`w-full lg:w-1/2 relative ${data.layout === 'left' ? 'order-1 lg:order-2' : 'order-1'}`}>
          <Image
            media={data.images.background}
            className="w-full h-full object-cover relative z-0"
          />
          {data.images.left && (
            <ScrollDisplay
              delay={0.5}
              className="w-2/3 h-auto absolute top-0 left-0"
            >
              <Image
                media={data.images.left}
                className=""
              />
            </ScrollDisplay>
          )}
          {data.images.right && (
            <ScrollDisplay
              delay={0.25}
              className="absolute right-12 w-1/3 top-0 h-auto active"
            >
              <Image
                media={data.images.right}
                className="top-20"
              />
              <SVG
                onError={error => console.log(error.message)}
                cacheRequests={true}
                preProcessor={code =>
                  code.replace(/fill=".*?"/g, 'fill="currentColor"')
                }
                src={stars}
                className="absolute z-10 top-0 xxl:top-20 left-28 sm:left-1/4 lg:left-32 xl:left-64 xxl:left-80 xxxl:left-96 text-white h-auto w-auto scale-75 sm:scale-100"
              />
            </ScrollDisplay>
          )}
        </div>
        <div className={`w-full lg:w-1/2 relative px-8 md:px-20 xxl:px-48 py-16 flex items-center  ${data.layout === 'left' ? 'order-2 lg:order-1' : 'order-2'}`}>
          {data.layout === 'left' ? (
            <StaticImage
              src='../../assets/img/dbd-beach-background-alt.jpg'
              alt=""
              placeholder="blurred"
              className="gatsby-absolute w-full h-full object-cover object-top left-0 top-0 pointer-events-none"
            />
          ) : (
            <StaticImage
              src='../../assets/img/dbd-beach-background.jpg'
              alt=""
              placeholder="blurred"
              className="gatsby-absolute w-full h-full object-cover object-top left-0 top-0 pointer-events-none"
            />
          )}
          <div className="relative flex flex-col justify-center lg:justify-start">
            <ScrollDisplay
              delay={0.25}
              className="w-full"
            >
              <h2 className={`mb-10 leading-none w-full ${data.layout === 'left' ? 'style-secondary' : 'style-tertiary'}`}>
                <svg className="w-full h-32 xxl:h-44">
                  <text x="50%" y="40%" textAnchor="middle" className={data.layout === 'left' ? 'style-secondary style-inverted small' : 'style-tertiary'}>
                    <Markdown
                      children={data.title}
                      type="svg"
                    />
                  </text>
                  <text x="50%" y="90%" textAnchor="middle" className={data.layout === 'left' ? 'style-secondary style-inverted small' : 'style-tertiary'}>
                    <Markdown
                      children={data.title2}
                      type="svg"
                    />
                  </text>
                </svg>
              </h2>
              <p className="mb-10 text-base text-brown font-bold gatsby-hidden-mobile">
                {data.text}
              </p>
              <p className="mb-10 text-base text-brown gatsby-hidden-mobile">
                {data.text2}
              </p>
              <p className="mb-10 text-base text-brown gatsby-hidden-desktop">
                {data.textMobile}
              </p>
            </ScrollDisplay>
            <ScrollDisplay
              className="w-full flex justify-center"
            >
              <Button
                label={data.button.text}
                link={data.button.link}
                trigger="externalLink"
                size={data.layout === 'left' ? 'button-styled-inverted' : 'button-styled'}
                className={`relative`}
              >
                <StyledButton style={data.layout === 'left' ? '' : 'alternate'} />
              </Button>
            </ScrollDisplay>
          </div>
        </div>
      </section>
    </>
  )
}

export default TextImage
