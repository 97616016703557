/**
 * Format data to be used in modal boxes
 */

export function getModalData(data) {
  const gameData = data.game.edges[0].node
  const ageGateData = null
  const stringsData = [
    { errors: [] },
    { dates: [] }
  ]
  const brandData = data.brand?.edges?.[0]?.node ? data.brand?.edges?.[0]?.node : data.brand

  const modalData = { gameData, brandData, stringsData, ageGateData }
  return modalData
}