import React, { useState, createContext } from 'react'
import SVG from 'react-inlinesvg'
import { motion } from 'framer-motion'

import Button from '@base/parts/button'

import AnimatedKeyart from '@elements/animated-keyart'
import StyledButton from '@elements/styled-button'
import VideoPlayer from '@elements/video'
import logo from '@img/hookedonyou-logo-large.svg'
import steamLarge from '@img/steam-logo.svg'
import play from '@icons/play.svg'

export const Context = createContext()

const Hero = ({ data, pageContext }) => {
  const [playing, setPlaying] = useState(false)
  const [videoShowing, setVideoShowing] = useState(false)

  function openVideo() {
    setVideoShowing(!videoShowing)
    setPlaying(!playing)
  }

  return (
    <Context.Provider value={{
      videoPlayer: [playing, setPlaying],
      videoPlayerAppearance: [videoShowing, setVideoShowing]
    }}>
      <section
        className="hero clip-wave relative lg:min-h-keyart h-screen-nav-mobile lg:h-screen-nav overflow-hidden flex flex-col justify-end lg:justify-center items-end -mb-20 mt-36 lg:mt-64"
        data-section="top"
      >
        <div id="top" className="absolute -top-96"></div>
        <AnimatedKeyart />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 1 }}
          className="relative z-10 flex justify-center flex-col items-center w-full lg:w-1/2 pb-32"
        >
          <SVG
            onError={error => console.log(error.message)}
            cacheRequests={true}
            src={logo}
            className="mb-8 lg:mb-12 xxl:mb-20 w-86 lg:w-[375px] xxl:w-[530px] h-auto"
          />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 2 }}
            className="text-center w-full"
          >
            <Button
              link={data.button.link}
              pageContext={pageContext}
              trigger="videoModal"
              className={`block lg:hidden p-6 lg:p-12 mx-auto transition-all duration-300 w-32 h-32`}
            >
              <SVG
                onError={error => console.log(error.message)}
                cacheRequests={true}
                src={play}
                className="video-btn w-full h-full overflow-visible transition-all duration-300"
              />
              <span className="sr-only">Play</span>
            </Button>

            <div className="flex justify-center flex-col items-center">
              <Button
                label={data.button2.text}
                link={data.button2.link}
                trigger="externalLink"
                size={'button-styled'}
                className={`relative tighter`}
              >
                <StyledButton style="alternate" />
              </Button>
              <SVG
                onError={error => console.log(error.message)}
                cacheRequests={true}
                preProcessor={code =>
                  code.replace(/fill=".*?"/g, 'fill="currentColor"')
                }
                src={steamLarge}
                className="w-44 lg:w-[200px] h-auto text-white lg:text-black mt-8 lg:mt-16"
              />
            </div>
          </motion.div>
        </motion.div>
        <VideoPlayer
          onClick={openVideo}
          source={data.button.link}
        />
      </section>
    </Context.Provider>
  )
}

export default Hero
