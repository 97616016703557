import React, { useEffect, useRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const AnimatedKeyart = (props) => {
  const lensFlare = useRef(null)

  useEffect(() => {
    document.addEventListener('mousemove', parallax)

    function parallax(event) {
      document.querySelectorAll('.keyart-background .mouse-animated').forEach((shift) => {
        const position = shift.getAttribute("value")
        const x = (window.innerWidth - event.pageX * position) / 90
        const y = (window.innerHeight - event.pageY * position) / 90
        shift.style.transform = `translateX(${x}px) translateY(${y}px)`
      })
    }
  }, [])

  return (
    <>
      <div
        className="keyart-background bg-blue -top-[3px] lg:top-0"
        ref={lensFlare}
      >
        <div
          className="absolute w-full h-full z-under left-0 top-0 mouse-animated"
          value="0.25"
        >
          <StaticImage
            src='../../assets/img/hookedonyou-keyart-background-2.jpg'
            alt=""
            placeholder="blurred"
            className="keyart-background-image object-cover gatsby-hidden-mobile"
          />
          <StaticImage
            src='../../assets/img/hookedonyou-keyart-background-mobile.jpg'
            alt=""
            placeholder="blurred"
            className="keyart-background-image object-cover gatsby-hidden-desktop"
          />
        </div>
        <div className="flare gatsby-hidden-mobile">
          <div
            className="subflare subflare1 mouse-animated"
            value="6"
          />
          <div
            className="subflare subflare2 mouse-animated"
            value="4"
          />
          <div
            className="subflare subflare3 mouse-animated"
            value="2"
          />
        </div>
        <div
          className="absolute w-full h-full z-10 left-0 top-0 mouse-animated"
          value="0.5"
        >
          <StaticImage
            src='../../assets/img/hookedonyou-keyart-layer-trapper-2.png'
            alt=""
            placeholder="blurred"
            className="keyart-background-image2 object-cover gatsby-hidden-mobile"
          />
          <StaticImage
            src='../../assets/img/hookedonyou-keyart-layer-trapper-mobile.png'
            alt=""
            placeholder="blurred"
            className="keyart-background-image2 object-cover gatsby-hidden-desktop"
          />
        </div>
      </div>

    </>
  )
}

export default AnimatedKeyart
