import React, { createContext, useState } from 'react'

const defaultState = {
  ageGateDisplayed: false,
  isLegalAge: false,
  formSubmitted: false
}
export const Context = createContext(defaultState)

const NewsletterContextProvider = props => {
  const [ageGateDisplayed, setAgeGateDisplayed] = useState(false)
  const [isLegalAge, setLegalAge] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  return (
    <Context.Provider value={{
      ageGatePrompt: [ageGateDisplayed, setAgeGateDisplayed],
      legalAge: [isLegalAge, setLegalAge],
      formSubmit: [formSubmitted, setFormSubmitted]
    }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewsletterContextProvider
