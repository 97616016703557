import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Parallax, CarouselWrapper, CarouselContent, CarouselTabs } from '@bhvr/web-design-system'

import ScrollDisplay from '@base/utils/helpers/scroll-display'
import Markdown from '@base/parts/markdown'
import Image from '@base/parts/image'
import useWindowSize from '@base/utils/hooks/useWindowSize'

const Gallery = ({ data }) => {
  const windowSize = useWindowSize()
  let sliderDirection = 'vertical'
  let slidePerView = 5
  let spaceBetween = 0
  if (windowSize.width <= 991) {
    sliderDirection = 'horizontal'
    slidePerView = 3
    spaceBetween = 30
  }

  return (
    <section
      className="gallery w-full relative h-auto flex flex-col lg:flex-col justify-end items-center -mt-40 pt-20 overflow-hidden"
      data-section="gallery"
    >
      <div id="gallery" className="absolute -top-16 lg:-top-4"></div>
      <Parallax
        className="w-full top-0 h-[120%] gatsby-absolute z-under"
        strength={-50}
        customContentClassName="w-screen h-full"
        customContent
      >
        <StaticImage
          src='../../assets/img/gallery-background.jpg'
          alt=""
          placeholder="blurred"
          className="w-full h-full object-cover object-top min-h-keyart"
        />
      </Parallax>

      <div className="w-full lg:w-11/12 xxl:w-3/4 xxxl:w-1200 mx-auto flex flex-col pt-20 lg:pt-32 px-10 lg:px-0">
        <ScrollDisplay className="flex flex-col lg:flex-row lg:pt-20">
          <div className="w-full lg:w-1/2 xxl:w-3/4 relative">
            <h2 className="h1 mb-12 md:mb-20 xl:mb-12 leading-none text-center w-full">
              <svg className="w-full h-32">
                <g className="gatsby-hidden-mobile">
                  <text x="50%" y="70%" textAnchor="middle">
                    <Markdown
                      children={data.title}
                      type="svg"
                    />
                    &nbsp;
                    <Markdown
                      children={data.title2}
                      type="svg"
                    />
                  </text>
                </g>
                <g className="gatsby-hidden-desktop">
                  <text x="50%" y="40%" textAnchor="middle">
                    <Markdown
                      children={data.title}
                      type="svg"
                    />
                  </text>
                  <text x="50%" y="90%" textAnchor="middle">
                    <Markdown
                      children={data.title2}
                      type="svg"
                    />
                  </text>
                </g>
              </svg>
            </h2>
          </div>
        </ScrollDisplay>
      </div>
      <div className="w-full lg:w-11/12 xxl:w-3/4 xxxl:w-1200 mx-auto flex flex-col pb-24 lg:pb-16 px-10 lg:px-0">
        <ScrollDisplay className="flex flex-col lg:flex-row lg:pt-20 lg:pb-96">
          <CarouselWrapper>
            <div className="w-full lg:w-2/3 relative">
              <div className="relative ratio w-full mb-20 lg:mb-40">
                <StaticImage
                  src='../../assets/img/gallery-frame.png'
                  alt=""
                  placeholder="blurred"
                  className="gatsby-absolute w-[110%] h-[110%] lg:h-[120%] lg:w-[120%] xxl:h-[126%] xxl:w-[126%] -top-[8%] -left-[5%] lg:-top-[9%] lg:-left-[9%] xxl:-top-[11.5%] xxl:-left-[11.5%] gatsby-image-contain"
                />
                <StaticImage
                  src='../../assets/img/skull-drink-ui.png'
                  alt=""
                  placeholder="blurred"
                  className="gatsby-absolute h-auto w-24 lg:w-[170px] -bottom-[10%] -right-8 lg:-bottom-28 lg:-right-[15%] gatsby-image-contain z-10"
                />
                <CarouselContent
                  className="gallery-main overflow-visible swiper-fade flex flex-col h-full w-full max-h-screen-3/4"
                  slideClassName=" flex flex-col"
                  direction={sliderDirection}
                  effect="fade"
                  slidesPerView={slidePerView}
                  centeredSlides
                  loop
                >
                  {data.slides && data.slides.map((slide) => (
                    <>
                      <Image
                        media={slide.image.full}
                        className="w-[90%] lg:w-full"
                      />
                      <div className="absolute flex flex-col w-full h-auto lg:max-h-96 top-[200%] sm:top-[170%] md:top-[145%] lg:top-[120%] mb-0" >
                        <span className="h3 w-full mb-8">
                          <svg className="w-full h-20 lg:h-20 -mb-10 lg:mb-0">
                            <text x="0%" y="70%" textAnchor="middle" className="style-fourth style-inverted scale-50 md:scale-60 lg:scale-100 translate-x-1/2">
                              <Markdown
                                children={slide.title}
                                type="svg"
                              />
                            </text>
                          </svg>
                        </span>
                        <p className="text-base text-white text-center w-full lg:w-3/4 mx-auto">
                          <Markdown
                            children={slide.text}
                          />
                        </p>
                      </div>
                    </>
                  ))}
                </CarouselContent>
              </div>
            </div>
            <div className="gallery-thumbs-wrapper w-full lg:w-1/3 flex relative lg:-right-[5%] xxl:-right-[10%] mb-64 lg:mb-0">
              <div className="gallery-thumbs w-full">
                <CarouselTabs
                  tabsPerView={slidePerView}
                  allowTouchMove
                  className="gallery-thumbs-inner flex flex-col w-full h-[110%] swiper-thumbs"
                  slideClassName=""
                  direction={sliderDirection}
                  centeredSlides
                  spaceBetween={spaceBetween}
                  loop
                >
                  {data.slides && data.slides.map((media, i) => (
                    <div className="gallery-tab w-full h-full flex justify-center items-center cursor-grab">
                      <figure className="w-full h-full flex justify-center items-center flex-col lg:flex-row">
                        <figcaption className="bg-blueLight flex items-center text-white py-6">
                        </figcaption>
                        <div className="gallery-tab-image w-full lg:w-60 h-full">
                          <Image
                            media={media.image.full}
                            className="h-full w-full object-cover border-[3px] border-white"
                          />
                        </div>
                      </figure>
                    </div>
                  ))}
                </CarouselTabs>
              </div>
            </div>

          </CarouselWrapper>
        </ScrollDisplay>
      </div>
    </section>
  )
}

export default Gallery
