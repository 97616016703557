import React, { useEffect, useContext, useRef } from 'react'
import SVG from 'react-inlinesvg'
import { Context } from '../blocks/hero'
import closeIcon from '@base/icons/close.svg'
import play from '@icons/play.svg'

const VideoPlayer = (props) => {
  const videoPlayerRef = useRef(null)
  const { videoPlayer, videoPlayerAppearance } = useContext(Context)
  const [playing, setPlaying] = videoPlayer
  const [videoShowing, setVideoShowing] = videoPlayerAppearance

  useEffect(() => {
    if (playing === true) {
      videoPlayerRef.current.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*')
    } else {
      videoPlayerRef.current.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*')
    }
  }, [playing])

  function closeVideo() {
    setVideoShowing(false)
    setPlaying(false)
  }

  return (
    <div className={`absolute w-full h-full top-0 left-0 ${playing ? 'is-playing' : 'is-paused'}`}>
      <button
        className={`hidden lg:block absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-30 w-40 h-40 xxl:w-[128px] xxl:h-[128px] ${playing ? 'opacity-20 hover:opacity-100' : 'opacity-100'}`}
        onClick={props.onClick}
      >
        <SVG
          onError={error => console.log(error.message)}
          cacheRequests={true}
          src={play}
          className="video-btn w-full h-full overflow-visible transition-all duration-300"
        />
        <span className="sr-only">Play</span>
      </button>
      <div className={`video-youtube-embed-container z-30 absolute h-full w-full transition-all duration-500 ${videoShowing ? 'opacity-1' : 'opacity-0 pointer-events-none'} bg-black`}>
        <iframe
          ref={videoPlayerRef}
          className="video-youtube-embed"
          src={`https://www.youtube-nocookie.com/embed/${props.source}?enablejsapi=1&showinfo=0&autohide=1&rel=1`}
          height="100%"
          width="100%"
          frameBorder="0"
          allow="autoplay"
        />
      </div>
      <div className={`absolute top-0 right-8 flex justify-center p-8 text-white z-30  ${videoShowing ? 'opacity-1' : 'opacity-0 pointer-events-none'}`}>
        <button
          className="button-close bg-white mt-2 flex h-14 w-14 items-center justify-center rounded-full"
          aria-label="close"
          onClick={closeVideo}
        >
          <SVG
            onError={error => console.log(error.message)}
            cacheRequests={true}
            preProcessor={code =>
              code.replace(/fill=".*?"/g, 'fill="currentColor"')
            }
            src={closeIcon}
            className="h-5 w-5 text-grayDark"
          />
        </button>
      </div>
    </div >
  )
}

export default VideoPlayer
