import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Parallax } from '@bhvr/web-design-system'

import ScrollDisplay from '@base/utils/helpers/scroll-display'
import Markdown from '@base/parts/markdown'

const Intro = ({ data, pageData }) => {
  return (
    <section
      className="w-full relative h-auto flex flex-col lg:flex-row justify-end items-center"
      data-section="welcome"
    >
      <div id="welcome" className="absolute -top-16 lg:-top-4"></div>
      <Parallax
        className="w-full h-full gatsby-absolute z-under"
        strength={-50}
        customContentClassName="w-screen h-full"
        customContent
      >
        <StaticImage
          src='../../assets/img/content-background.jpg'
          alt=""
          placeholder="blurred"
          className="w-full h-full object-cover object-top min-h-keyart"
        />
      </Parallax>
      <div className="w-full lg:w-1/4 xl:w-1/3 h-60 lg:h-full p-8 lg:pt-20 xl:p-24 absolute lg:relative z-under top-12 sm:-top-8 lg:top-0">
        <div className="w-full h-full relative">
          <ScrollDisplay delay={0.5} className="gatsby-hidden-mobile">
            <StaticImage
              src='../../assets/img/emojis/hoy-left-emojis-1.png'
              alt=""
              placeholder="blurred"
              className="h-full w-full floating"
            />
            <StaticImage
              src='../../assets/img/emojis/hoy-left-emojis-2.png'
              alt=""
              placeholder="blurred"
              className="gatsby-absolute h-full w-full left-0 top-0 floating-reverse"
            />
          </ScrollDisplay>
          <ScrollDisplay delay={0.75} className="gatsby-hidden-mobile">
            <StaticImage
              src='../../assets/img/emojis/hoy-left-emojis-3.png'
              alt=""
              placeholder="blurred"
              className="gatsby-absolute h-full w-full left-0 top-0 floating-reverse-2"
            />
            <StaticImage
              src='../../assets/img/emojis/hoy-left-emojis-4.png'
              alt=""
              placeholder="blurred"
              className="gatsby-absolute h-full w-full left-0 top-0 floating-2"
            />
          </ScrollDisplay>
          <ScrollDisplay delay={0.5} className="gatsby-hidden-desktop h-full">
            <StaticImage
              src='../../assets/img/emojis/hoy-top-emojis-1.png'
              alt=""
              placeholder="blurred"
              className="emoji gatsby-absolute h-auto w-full left-0 top-0 floating"
            />
            <StaticImage
              src='../../assets/img/emojis/hoy-top-emojis-2.png'
              alt=""
              placeholder="blurred"
              className="emoji gatsby-absolute h-auto w-full left-0 top-0 floating-reverse"
            />
            <StaticImage
              src='../../assets/img/emojis/hoy-top-emojis-3.png'
              alt=""
              placeholder="blurred"
              className="emoji gatsby-absolute h-auto w-full left-0 top-0 floating-2"
            />
          </ScrollDisplay>
        </div>
      </div>
      <ScrollDisplay className="w-full lg:w-1/2 xl:w-1/3 relative z-10 flex justify-center flex-col items-center pt-48 pb-60 lg:py-48">
        <h2 className="mb-12 leading-none text-center w-full">
          <svg className="w-full h-32 xxl:h-44">
            <text x="50%" y="40%" textAnchor="middle">
              <Markdown
                children={data.title}
                type="svg"
              />
            </text>
            <text x="50%" y="90%" textAnchor="middle">
              <Markdown
                children={data.title2}
                type="svg"
              />
            </text>
          </svg>
        </h2>
        <p className="text-base font-bold text-white lg:text-center mb-0 px-8 lg:px-0">
          <Markdown
            children={data.text}
          />
        </p>
      </ScrollDisplay>
      <div className="w-full lg:w-1/4 xl:w-1/3 h-60 lg:h-full p-8 lg:pt-20 xl:p-24 absolute lg:relative z-under bottom-0">
        <div className="w-full h-full relative">
          <ScrollDisplay delay={0.75} className="gatsby-hidden-mobile">
            <StaticImage
              src='../../assets/img/emojis/hoy-right-emojis-1.png'
              alt=""
              placeholder="blurred"
              className="h-full w-full floating-reverse"
            />
            <StaticImage
              src='../../assets/img/emojis/hoy-right-emojis-2.png'
              alt=""
              placeholder="blurred"
              className="gatsby-absolute h-full w-full left-0 top-0 floating-2"
            />
          </ScrollDisplay>
          <ScrollDisplay delay={0.5} className="gatsby-hidden-mobile">
            <StaticImage
              src='../../assets/img/emojis/hoy-right-emojis-3.png'
              alt=""
              placeholder="blurred"
              className="gatsby-absolute h-full w-full left-0 top-0 floating-reverse-2"
            />
            <StaticImage
              src='../../assets/img/emojis/hoy-right-emojis-4.png'
              alt=""
              placeholder="blurred"
              className="gatsby-absolute h-full w-full left-0 top-0 floating"
            />
          </ScrollDisplay>
          <ScrollDisplay delay={0.5} className="gatsby-hidden-desktop h-full z-under">
            <StaticImage
              src='../../assets/img/emojis/hoy-bottom-emojis-1.png'
              alt=""
              placeholder="blurred"
              className="emoji gatsby-absolute h-auto w-full left-0 top-0 floating"
            />
            <StaticImage
              src='../../assets/img/emojis/hoy-bottom-emojis-2.png'
              alt=""
              placeholder="blurred"
              className="emoji gatsby-absolute h-auto w-full left-0 top-0 floating-reverse"
            />
            <StaticImage
              src='../../assets/img/emojis/hoy-bottom-emojis-3.png'
              alt=""
              placeholder="blurred"
              className="emoji gatsby-absolute h-auto w-full left-0 top-0 floating-2"
            />
          </ScrollDisplay>
        </div>
      </div>
    </section>
  )
}

export default Intro
