import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Parallax } from '@bhvr/web-design-system'

import Newsletter from '@elements/newsletter'
import NewsletterContextProvider from '@/contexts/newsletter-context'

const Subscribe = ({ pageContext }) => {
  const newsletterForm = pageContext.globalData.global.edges[0].node.newsletterForm
  const error = {
    newsletterConsentError: newsletterForm.newsletterConsentError,
    newsletterEmailError: newsletterForm.newsletterEmailError,
    newsletterGenericError: newsletterForm.newsletterGenericError,
    newsletterSuccess: newsletterForm.newsletterSuccess
  }
  const newsletterData = {
    agreementText: newsletterForm.agreementText,
    emailButton: newsletterForm.emailButton,
    emailLabel: newsletterForm.emailLabel,
    emailPlaceholder: newsletterForm.emailPlaceholder,
    legalText: newsletterForm.legalText,
    description: newsletterForm.description,
    title: newsletterForm.title,
    title2: newsletterForm.title2
  }
  const ageGateData = ''

  return (
    <section
      className="relative h-auto flex flex-row justify-center items-center bg-primary pt-0 overflow-hidden"
      data-section="subscribe"
    >
      <div id="subscribe" className="absolute -top-16 lg:-top-36"></div>
      <StaticImage
        src='../../assets/img/newsletter-blue-background.jpg'
        alt=""
        placeholder="blurred"
        className="gatsby-absolute w-full h-full min-h-screen md:min-h-[720px] object-cover object-top -mt-[55px] md:-mt-[44px]"
      />
      <Parallax
        className="w-full h-full gatsby-absolute z-0 mix-blend-overlay"
        strength={-100}
        customContentClassName="w-screen h-full min-h-keyart"
        customContent
      >
        <div className="w-200 h-200 bg-blue absolute -bottom-96 right-0 clip-diagonal opacity-20" />
      </Parallax>
      <Parallax
        className="w-full h-full gatsby-absolute z-0 mix-blend-overlay"
        strength={100}
        customContentClassName="w-screen h-full min-h-keyart"
        customContent
      >
        <div className="w-100 h-100 bg-blueLight absolute bottom-0 right-0 clip-diagonal opacity-20 mix-bl" />
      </Parallax>
      <div className="relative w-full text-white pt-20 pb-52 lg:pt-48 lg:pb-72">
        <NewsletterContextProvider>
          <Newsletter
            data={{ newsletterData, ageGateData }}
            strings={{ error }}
            pageContext={pageContext}
            list="deadbydaylight"
          />
        </NewsletterContextProvider>
      </div>
    </section>
  )
}

export default Subscribe
